<template>
  <image-content has-grey-bg>
    <template #image-headline>
      <h1
        class="Headline is-size-4"
        v-t="'pages.profileEdit.title'"
      />
    </template>

    <template #image>
      <figure>
        <img
          :src="imageUrl"
          :alt="$t(`userRoles.${userTypeKey}`)"
        />
      </figure>
    </template>

    <template #content>
      <div id="RegisterForm" class="Content has-grey-bg">
        <component :is="editType" is-edit />
      </div>
    </template>
  </image-content>
</template>
<script>

import { mapGetters } from 'vuex'
import ImageContent from '@/layouts/ImageContent'
export default {
  name: 'PortalProfileEdit',

  metaInfo () {
    return {
      title: this.$i18n.t('pages.profileEdit.meta.title')
    }
  },

  components: {
    ImageContent,
    RegisterFormCaregiver: () => import('@/components/RegisterFormCaregiver.vue'),
    RegisterFormProfessional: () => import('@/components/RegisterFormProfessional.vue'),
    RegisterFormRecipient: () => import('@/components/RegisterFormRecipient.vue')
  },

  mounted () {
    if (this.user.registrationType !== 'basic' && this.user.registrationStatus !== 'rejected') {
      this.$router.push({
        name: 'profile'
      })
    }
  },

  computed: {
    ...mapGetters('user', [
      'user',
      'isProfessional',
      'isCareGiver'
    ]),

    userTypeKey () {
      if (!this.user.userType) return 'recipient'

      return this.user.userType.toLowerCase()
    },

    imageUrl () {
      return require(`@/assets/images/avatar-${this.userTypeKey}.png`)
    },

    editType () {
      if (this.isProfessional) return 'register-form-professional'
      if (this.isCareGiver) return 'register-form-caregiver'

      return 'register-form-recipient'
    }
  }
}
</script>
